import React from "react";

import GenericSite from "../components/GenericSite";
import a11yDeclarationS1 from "../snippets/a11y-1-01-deklaracja-dostepnosci.html";
import a11yDeclarationS2 from "../snippets/a11y-2-01-stan-dostepnosci-cyfrowej.html";
import a11yDeclarationS3 from "../snippets/a11y-2-02-niedostepne-tresci.html";
import a11yDeclarationS4 from "../snippets/a11y-2-03-przygotowanie-deklaracji-dostepnosci.html";
import a11yDeclarationS5 from "../snippets/a11y-2-04-informacje-zwrotne-i-dane-kontaktowe.html";
import a11yDeclarationS6 from "../snippets/a11y-2-05-obsluga-wnioskow-i-skarg-zwiazanych-z-dostepnoscia.html";
import a11yDeclarationS7 from "../snippets/a11y-2-06-pozostale-informacje.html";

const A11yDeclaration = () => {
  return (
    <GenericSite
      title="Deklaracja dostępności"
      sections={[
        {
          id: "1",
          title: "",
          text: a11yDeclarationS1,
        },
        {
          id: "2",
          title: "Stan dostępności cyfrowej",
          text: a11yDeclarationS2,
        },
        {
          id: "3",
          title: "Niedostępne treści",
          text: a11yDeclarationS3,
        },
        {
          id: "4",
          title: "Przygotowanie deklaracji dostępności",
          text: a11yDeclarationS4,
        },
        {
          id: "5",
          title: "Informacje zwrotne i dane kontaktowe",
          text: a11yDeclarationS5,
        },
        {
          id: "6",
          title: "Obsługa wniosków i skarg związanych z dostępnością",
          text: a11yDeclarationS6,
        },
        {
          id: "7",
          title: "Pozostałe informacje",
          text: a11yDeclarationS7,
        },
      ]}
    />
  );
};

export default A11yDeclaration;